@import "../../node_modules/bootstrap/scss/bootstrap";
@import "../../node_modules/rc-time-picker/assets/index";

.rc-time-picker-input,
.rc-time-picker-panel-select li,
.rc-time-picker-panel-input {
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    height: 34px;
}

.rbc-month-row {
    min-height: 120px;
}

.rbc-month-row + .rbc-month-row {
    min-height: 120px;
}

[data-field-box] {
    &[data-field="Tabelle"] {
        tr,
        td,
        th {
            break-inside: avoid;
        }
        margin-top: 20px !important;
    }
}

#main-tabpane-editor .fields [data-field-box] {
    width: calc(100% - 40px) !important;
}

.field-wrapper {
    border: 1px solid #ddd;
    margin-bottom: 20px;
}

.myEmpty {
    border: 1px solid red;
}

.field-body label {
    margin-bottom: 0 !important;
    font-weight: 500;
}

.react-contextmenu-wrapper {
    width: 100%;
}

.sk-folding-cube {
    margin: 20px auto;
    width: 40px;
    height: 40px;
    position: relative;
    -webkit-transform: rotateZ(45deg);
    transform: rotateZ(45deg);
}

.sk-folding-cube .sk-cube {
    float: left;
    width: 50%;
    height: 50%;
    position: relative;
    -webkit-transform: scale(1.1);
    -ms-transform: scale(1.1);
    transform: scale(1.1);
}

.sk-folding-cube .sk-cube:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #dadada;
    -webkit-animation: sk-foldCubeAngle 2.4s infinite linear both;
    animation: sk-foldCubeAngle 2.4s infinite linear both;
    -webkit-transform-origin: 100% 100%;
    -ms-transform-origin: 100% 100%;
    transform-origin: 100% 100%;
}

.sk-folding-cube .sk-cube2 {
    -webkit-transform: scale(1.1) rotateZ(90deg);
    transform: scale(1.1) rotateZ(90deg);
}

.sk-folding-cube .sk-cube3 {
    -webkit-transform: scale(1.1) rotateZ(180deg);
    transform: scale(1.1) rotateZ(180deg);
}

.sk-folding-cube .sk-cube4 {
    -webkit-transform: scale(1.1) rotateZ(270deg);
    transform: scale(1.1) rotateZ(270deg);
}

.sk-folding-cube .sk-cube2:before {
    -webkit-animation-delay: 0.3s;
    animation-delay: 0.3s;
}

.sk-folding-cube .sk-cube3:before {
    -webkit-animation-delay: 0.6s;
    animation-delay: 0.6s;
}

.sk-folding-cube .sk-cube4:before {
    -webkit-animation-delay: 0.9s;
    animation-delay: 0.9s;
}

@-webkit-keyframes sk-foldCubeAngle {
    0%,
    10% {
        -webkit-transform: perspective(140px) rotateX(-180deg);
        transform: perspective(140px) rotateX(-180deg);
        opacity: 0;
    }
    25%,
    75% {
        -webkit-transform: perspective(140px) rotateX(0deg);
        transform: perspective(140px) rotateX(0deg);
        opacity: 1;
    }
    90%,
    100% {
        -webkit-transform: perspective(140px) rotateY(180deg);
        transform: perspective(140px) rotateY(180deg);
        opacity: 0;
    }
}

@keyframes sk-foldCubeAngle {
    0%,
    10% {
        -webkit-transform: perspective(140px) rotateX(-180deg);
        transform: perspective(140px) rotateX(-180deg);
        opacity: 0;
    }
    25%,
    75% {
        -webkit-transform: perspective(140px) rotateX(0deg);
        transform: perspective(140px) rotateX(0deg);
        opacity: 1;
    }
    90%,
    100% {
        -webkit-transform: perspective(140px) rotateY(180deg);
        transform: perspective(140px) rotateY(180deg);
        opacity: 0;
    }
}

.cke {
    width: 100% !important;
    border: none !important;
}

.field-body {
}

@keyframes highlight {
    from {
        background-color: lemonchiffon;
    }
    to {
        background-color: #fff;
    }
}

.highlight .field-body,
.highlight .card-header {
    animation: highlight ease-in-out 0.5s;
}

.field-body--collapsed {
    display: none;
}

.rpfe-small .card-header {
    padding: 0 10px;
}

/* utils */
.powerform-flex {
    display: flex;
    justify-content: space-evenly;
}

.powerform-flex--between {
    justify-content: space-between;
}

.powerform-w50 {
    width: 50%;
    margin: 0 20px;
}

.powerform-w100 {
    width: 100%;
    margin: 0 20px;
}

.powerform-label {
}

.powerform-label--muted {
    opacity: 0.5;
}

.powerform-hint {
    margin: 10px 0;
    padding: 6px 12px;
    line-height: 18px;
    font-size: 13px;
    background-color: rgba(255, 218, 0, 0.3);
    border: 1px solid rgba(255, 218, 0, 1);
}

.rpfe-small .card-header .edit-header-actions button {
    padding: 0px 8px;
    margin: 4px 2px;
}

.edit-header {
    padding: 6px 15px;
    font-size: 18px;
    background: linear-gradient(to top, rgba(214, 214, 214, 0.7), #f1f1f1);
    text-shadow: 1px 1px 0 #fff;
    border-bottom: 1px solid #ccc;
}

.edit-header .powerform-flex {
    align-items: center;
}

.edit-header .square {
    display: inline-block;
    width: 12px;
    height: 12px;
    margin-right: 10px;
}

.edit-header .edit-header-actions {
}

.edit-header .edit-header-action {
    padding-left: 20px;
}

.edit-header .edit-header-action i {
    font-size: 14px;
    color: #555;
}

$primary: #5fc1d1;
body {
    overflow-x: hidden;
    display: block !important;
}

.tr-active {
    background-color: rgba(255, 255, 0, 0.05);
}

a {
    color: inherit;

    &:hover {
        text-decoration: none;
    }
}

.preview-container {
    //[data-id="FORMFOOTER"] {
    //    bottom: -250px !important;
    //}
}

.device_task--due {
    color: #ffce34;
}

.device_task--done {
    color: #4ab63b;
}

.designer,
.viewer,
.presenter {
    justify-content: space-around;

    .form {
        margin: 20px 0;
        background: #fff;
        min-height: 600px;
        padding: 20px 0;
        width: 100%;
        box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
    }

    [data-designer-form],
    [data-designer-footer],
    [data-viewer-form],
    [data-presenter-form] {
        padding: 0 20px;
    }
}

// vendor
/**
 *
 * SWITCH
 *
*/
.DateRangePicker {
    display: block !important;
    margin: 10px 0 !important;
}

.switch--success {
    position: relative;
    margin-bottom: 30px;
}

.switch--success {
    width: 60px;
}

.switch {
    background-color: transparent;
    box-sizing: border-box;
    display: flex;
    flex: 0 0 auto;
    height: 24px;
    margin: 0 0 10px;
    margin-left: 15px !important;
    overflow: hidden;
    padding: 0;
    position: relative;
    width: 60px;

    &__label {
        cursor: pointer;
        margin: 0;
        padding: 0;
        width: 100%;
    }

    &__input {
        opacity: 0;

        &:checked {
            ~ .switch__text {
                background: #5fc1d1;

                &:before {
                    opacity: 0;
                    transform: translateX(200%);
                }

                &:after {
                    opacity: 1;
                    transform: translateX(0);
                }
            }

            ~ .switch__handle {
                transform: translateX(36px);
            }
        }
    }

    &__text {
        background-color: #e5e5e5;
        border-radius: 50px;
        box-sizing: border-box;
        display: block;
        flex: 0 0 auto;
        height: 24px;
        margin: 0;
        position: absolute;
        top: 0;
        width: 60px;

        &:before,
        &:after {
            color: SlateGrey;
            font-size: 12px;
            font-weight: 700;
            line-height: 25px;
            position: absolute;
            transition: all 0.2s ease-in-out;
            transition-property: transform;
        }

        &:before {
            content: attr(data-off);
            right: 8px;
            transform: translateX(0);
        }

        &:after {
            color: white;
            content: attr(data-on);
            left: 9px;
            opacity: 0;
            transform: translateX(-200%);
        }
    }

    &__handle {
        background-color: white;
        border-radius: 18px;
        display: block;
        height: 20px;
        margin: 2px;
        padding: 0;
        position: absolute;
        top: 0;
        transition: all 0.2s ease-in-out;
        transition-property: transform;
        width: 20px;
    }

    &--large {
        height: 30px;
        width: 70px;

        .switch__input {
            &:checked {
                ~ .switch__handle {
                    transform: translateX(40px);
                }
            }
        }

        .switch__text {
            height: 30px;
            width: 70px;

            &:before,
            &:after {
                font-size: 14px;
                line-height: 30px;
                position: absolute;
            }

            &:before {
                right: 10px;
            }

            &:after {
                left: 12px;
            }
        }

        .switch__handle {
            height: 26px;
            width: 26px;
        }
    }

    &--no-text {
        .switch__text {
            &:before,
            &:after {
                display: none;
            }
        }
    }

    &--success {
        margin-left: 10px;

        .switch__input {
            &:checked {
                ~ .switch__text {
                    background: #5fc1d1;
                }
            }
        }
    }
}

.rpfe-field {
    display: block;
    width: 100%;
}

.react-tiny-popover-container {
    z-index: 200;
}

.rpfe-component {
    width: 100%;
}

// fixes treeview dragging issues
.m-draggable {
    z-index: 10;
}

.nav-pills {
    padding: 5px 0 10px;
}

.d-flex-inner {
    margin: 0 -20px !important;
    flex-wrap: wrap;
}

.DateInput_input {
    padding: 5px 12px !important;
    background: none !important;
    border-top: 2px solid transparent;
    font-size: 16px !important;
    font-weight: 400;
}

.DateRangePicker {
    margin: 0 !important;
}

.SingleDatePickerInput,
.DateRangePickerInput {
    background: none;
    border-radius: 5px !important;

    .DateInput {
        border-radius: 0;
        background: none !important;
    }
}

.rpfe-label h1 {
    font-size: 20px;
}

.rpfe-description p {
    font-size: 14px;
    color: #888;
    margin-bottom: 10px;
}

.rpfe-label p {
    margin: 0;
    min-height: 28px;
}

.nav-pills .nav-link {
    margin: 0 10px;
}

.switch--success {
    margin-bottom: 0 !important;
}

.switch__label {
    width: 60px !important;
}

/**
 * pdf print styles
 */

.sheet {
    margin: 0;
    overflow: hidden;
    position: relative;
    box-sizing: border-box;
    //    page-break-after: always;
}

/** Paper sizes **/
body.A3 .sheet {
    width: 297mm;
    height: 419mm;
}

body.A3.landscape .sheet {
    width: 420mm;
    height: 296mm;
}

body.A4 .sheet {
    width: 210mm;
}

body.A4.landscape .sheet {
    width: 297mm;
}

body.A5 .sheet {
    width: 148mm;
    height: 209mm;
}

body.A5.landscape .sheet {
    width: 210mm;
    height: 147mm;
}

body.letter .sheet {
    width: 216mm;
    height: 279mm;
}

body.letter.landscape .sheet {
    width: 280mm;
    height: 215mm;
}

body.legal .sheet {
    width: 216mm;
    height: 356mm;
}

body.legal.landscape .sheet {
    width: 357mm;
    height: 215mm;
}

/** Padding area **/
.sheet.padding-10mm {
    padding: 10mm;
}

.sheet.padding-15mm {
    padding: 15mm;
}

.sheet.padding-20mm {
    padding: 20mm;
}

.sheet.padding-25mm {
    padding: 25mm;
}

/** For screen preview **/
@media screen {
    body {
        background: #f1f1f1;
    }
    .sheet {
        flex-direction: column;
        background: white;
        box-shadow: 0 0.5mm 2mm rgba(0, 0, 0, 0.3);
        margin: 5mm auto;

        .switch__label {
            position: relative;
            left: -40px;
        }

        &:last-child {
            padding-bottom: 100px;
        }
    }
}

body {
    margin: 0;
}

.sheet [data-id="FORMFOOTER"] {
    //page-break-inside: avoid;
    position: relative;
    bottom: 0 !important;
    top: 0 !important;
    left: 0 !important;
    right: 0 !important;
}

@media print {
    .sheet [data-id="Header"] {
        margin: 0 -20px;
        padding: 20px 0;
    }

    .sheet [data-field-box][data-field^="Texteingabe (Mehrzeilig)"] {
        width: calc(75% - 40px) !important;
        margin-bottom: 20px !important;
    }

    .sheet .price-table .pdf-valign-top > div[class^="css"] {
        height: 34px;
    }

    /** Hide index */
    .sheet .price-table tbody tr td:first-child {
        display: none;
    }

    // Hide voucher
    #voucher {
        display: none !important;
    }

    .sheet .price-table tbody tr td:nth-child(4) {
        input {
            text-align: right !important;
            padding: 0 !important;
        }
    }

    .sheet .price-table tbody tr td:nth-child(5) {
        input {
            text-align: right !important;
            padding: 0 !important;
            padding-right: 5px !important;
        }
    }

    .sheet .price-table tbody tr td:nth-child(6) {
        input {
            text-align: right !important;
            padding: 0 !important;
            padding-right: 5px !important;
        }
    }

    .sheet .price-table thead tr th:first-child {
        display: none;
    }

    .sheet .price-table tbody tr td:last-child {
        display: none;
    }

    .sheet .price-table thead tr th:last-child {
        display: none;
    }

    .sheet [data-field="Rechnungs-Positionen"] tr td:nth-child(2) {
        vertical-align: middle;
    }

    .sheet [data-field="Rechnungs-Positionen"] thead th {
        text-align: center;
        height: 30px;
        vertical-align: middle;
    }

    .sheet [data-field="Rechnungs-Positionen"] thead th {
        text-align: center;
        height: 30px;
        vertical-align: middle;
    }

    // SelectBoxes
    .sheet [data-field="Rechnungs-Positionen"] tr [class$="-container"] {
        height: 24px !important;

        > * {
            min-height: auto !important;
        }

        [class$="-Input"] {
            display: none !important;
        }

        [class$="-ValueContainer"],
        [class$="-singleValue"],
        [class$="-control"] {
            height: 24px;

            * {
                text-align: center;
                padding: 0;
                margin: 0 !important;
            }
        }

        [class$="-singleValue"] {
            margin: 0 auto !important;
            display: block;
            width: 100%;
            position: relative;
            top: 15px;
            float: right;
            text-align: right;
            width: 100%;
            padding-right: 1px;
        }
    }

    .input-group input[type="number"] {
        padding: 0 !important;
    }

    .input-group .input-group-text {
        padding: 0 !important;
        right: 4px;
        position: relative;
        top: 1px;
    }

    .sheet [data-field="Rechnungs-Positionen"] input {
        height: 24px !important;
    }

    .sheet [data-field="Rechnungs-Positionen"] .input-group input {
        min-width: 59px !important;
        padding-right: 10px !important;
        text-align: right !important;
    }
    .sheet .input-group-append span {
        font-size: 12px !important;
        padding-top: 10px;
    }

    .sheet [data-field="Rechnungs-Positionen"] .input-group-text {
        background: transparent !important;
        border: none !important;
    }

    .sheet [data-field="Rechnungs-Positionen"] tr [class$="-container"] > div {
        border: none;
        font-size: 12px;
        padding-left: 2px !important;
        background: transparent;
    }

    .sheet [data-field="Rechnungs-Positionen"] tr [class$="-control"] > div:last-child {
        display: none;
    }

    .sheet [data-field="Rechnungs-Positionen"] * {
        font-size: 12px !important;
    }

    .sheet .input-group {
        background: none;
        border: none;
    }

    .sheet .input-group-text {
        padding-left: 0 !important;
    }

    .sheet [data-field="Rechnungs-Positionen"] [class$="-IndicatorsContainer"] {
        display: none;
    }

    .sheet [data-field="Rechnungs-Positionen"] input {
        border: none;
        background: transparent;
        font-size: 14px;
    }

    .sheet [data-field="Rechnungs-Positionen"] button {
        display: none;
    }
    .sheet .was-validated {
        display: none;
    }

    .sheet textarea.form-control {
        border-radius: 0;
        margin: 10px 0px;
    }

    #billing_adress {
        margin-top: -30px;
    }

    #billing_adress .rpfe-component p {
        margin-bottom: 50px !important;
    }

    .sheet [data-field="Rechnungs-Positionen"] .table tr td {
        vertical-align: middle;
        font-size: 14px;
    }

    .table-bordered .input-group {
        display: flex;
        align-items: center;
    }

    .dropdown.btn-group {
        display: none !important;
    }
    body {
        background: transparent !important;
    }
    table {
    }

    [data-form="F12"] [data-field="Tabelle"] {
        &,
        .rpfe-field {
            page-break-inside: auto !important;
            break-inside: auto !important;

            table.table {
                thead {
                    th {
                        background-color: #ccc !important;
                    }
                }
            }
        }
    }

    .hidden-print {
        display: none !important;
    }

    .visible-print {
        display: block;
    }

    .table-responsive {
        overflow: auto;
    }

    .table-responsive table {
        border: 1px solid #ccc;
    }

    .table-responsive tr {
        border: 1px solid #ccc;
        word-break: break-word;
        hyphens: auto;
        padding: 0px;
    }

    .table-responsive th {
        border: 1px solid #ccc;
        padding: 0px !important;
        font-size: 9px !important;
        word-break: keep-all !important;
        hyphens: auto;
        font-weight: 600 !important;
        vertical-align: middle !important;
    }

    .table-responsive td {
        border: 1px solid #ccc;
        word-wrap: break-word;
        word-break: break-word;
        hyphens: auto;
        padding: 0px;
        text-align: center;
    }

    .sheet {
    }

    body,
    body .container {
        min-width: 0 !important;
    }

    .rpfe-field .rpfe-component .form-group {
        margin-bottom: 0 !important;
    }
    [data-field="Footer"] {
        width: 100%;
    }
    //.rpfe-field {
    //    page-break-inside: avoid;
    //}
    body.A3.landscape {
        width: 420mm;
    }
    body.A3,
    body.A4.landscape {
        width: 297mm;
    }
    body.A4,
    body.A5.landscape {
        width: 210mm;
    }
    body.A5 {
        width: 148mm;
    }
    body.letter,
    body.legal {
        width: 216mm;
    }
    body.letter.landscape {
        width: 280mm;
    }
    body.legal.landscape {
        width: 357mm;
    }

    .hide-print {
        display: none;
    }

    thead {
        display: table-header-group !important;
    }
    tfoot {
        display: table-row-group !important;
    }
    tr {
        page-break-inside: avoid !important;
    }
}

.visible-print {
    display: none;
}

.hidden {
    display: none !important;
}

.print-area {
    .sheet {
        .dropdown.btn-group {
            display: none;
        }

        hr {
            margin-top: 0.5rem !important;
            margin-bottom: 0.5rem !important;
        }

        .form-group {
            margin-bottom: 0 !important;
        }

        .switch__label {
            height: 10px !important;
        }

        .rpfe-field .rpfe-component > .form-group {
            margin-bottom: 0 !important;

            p {
                margin-bottom: 0 !important;
            }

            > div[maxlength] {
                margin-top: 0 !important;

                .btn-primary {
                    display: none !important;
                }
            }
        }

        h4 {
            font-size: 18px;
        }

        .rpfe-field h4 {
            margin-bottom: 0 !important;
        }

        font-size: 12px;

        .switch__input {
            display: none;
        }

        .switch__text {
            position: relative !important;
        }

        [data-header] {
            margin: 0px;
            padding: 20px;

            [data-title] {
                display: flex;
                align-items: center;
                font-size: 20px;
                font-weight: 300;
            }

            [data-logo] {
                display: block;
                height: 100px !important;
            }
        }

        [data-field="Signatur"] {
            img {
                width: 120px;
            }
        }

        [data-field="Switch"] {
            select {
                -webkit-appearance: none !important;
                background: transparent !important;
                border: none;
                font-size: 12px;
                margin-left: -12px;
                margin-top: -12px;
                padding-bottom: 0 !important;
            }
        }

        [data-field="Paragraph"] {
            font-size: 10px;
            color: #999;

            p {
                margin: 0;
            }

            strong {
                color: #000;
                font-size: 12px;
            }
        }

        .rpfe-footnote {
            line-height: 14px;
            font-size: 10px !important;
            border-top: 1px solid #999;
            margin-top: 10px;
            padding: 0 !important;
        }

        .nav-pills {
            padding: 0;
            margin-top: 0px;
            margin-bottom: 10px;
            align-items: flex-end;

            .nav-item {
                a {
                    padding: 5px 10px;
                    margin: 0;
                }
            }

            .nav-item-inactive {
                display: none;
            }

            .nav-item-active + .nav-item-inactive {
                display: none;
            }
        }

        .rpfe-label {
            p,
            strong {
                min-height: 0;
                font-weight: 600;
            }

            p > div {
                min-height: 0;
                font-weight: 600;
            }
        }

        [data-field="Ja/Nein Auswahl"] {
            .form-group > .d-flex {
                flex-direction: column;

                div[aria-label] {
                    display: inline-flex !important;
                }

                input[type="checkbox"] {
                    top: -5px !important;
                    left: 0px !important;
                }
            }

            .rpfe-label {
                line-height: 28px;
            }

            .switch__label {
                position: relative;
                left: -19px;
                top: -5px;

                .switch__text:before {
                    right: 15px;
                }

                .switch__input:checked ~ .switch__text:after {
                    opacity: 1;
                    transform: translateX(15px);
                }

                .switch__text {
                    background: transparent !important;

                    &:before {
                        color: #000;
                        font-weight: 400;
                        transform: translateX(0px);
                        top: -5px;
                        left: 18px !important;
                    }

                    &:after {
                        color: #000 !important;
                        left: 3px !important;
                        font-weight: 400;
                        top: -5px;
                        transform: translateX(0px);
                    }
                }
            }

            .switch__handle {
                display: none;
            }
        }

        .table-responsive .btn-group {
            display: none;
        }

        .hidden-print {
            display: none;
        }

        .visible-print {
            display: block;
        }

        .rpfe-description p {
            margin: 0;
            font-size: 10px;
        }

        [data-id="SIGNATURE"] {
            img {
                max-width: 140px !important;
            }
        }

        table.table {
            border: 1px solid #ccc;

            thead {
                th {
                    border: 1px solid #ccc;
                    padding: 0;
                }
            }

            tbody {
                td {
                    border: 1px solid #ccc;
                    padding: 0 4px;
                    text-align: center;
                }
            }

            &.price-table {
                tbody {
                    tr td:last-child {
                        white-space: nowrap;
                    }
                }
            }
        }
        ul {
            white-space: normal;
        }
    }
}

.data-presenter [data-id="FORMFOOTER"] {
    position: absolute;
    top: 50px !important;
}

.pagebreak {
    clear: both;
    break-after: page;
    break-after: all;
    break-after: always;
    margin: 0;
    padding: 0;
}

body {
    -webkit-print-color-adjust: exact !important;
}

::placeholder {
    color: transparent !important;
}

.rpfe-footnote {
    text-align: center;
    font-size: 12px !important;
    color: #999;
    padding: 30px;
    padding-top: 50px;
    position: absolute;
    bottom: 0;
    left: 0;
    pointer-events: none;
    right: 0;
}

.form-container form {
    position: relative;
    padding-bottom: 100px;
}
