body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
        "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.react-tiny-popover-container .popover-body {
    padding: 0;
    background-color: #fff;
}

/* The Modal (background) */
.modal {
    display: none; /* Hidden by default */
    position: fixed; /* Stay in place */
    z-index: 1; /* Sit on top */
    padding-top: 100px; /* Location of the box */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgb(0, 0, 0); /* Fallback color */
    background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
}

/* Modal Content */
.modal-content {
    background-color: #fefefe;
    margin: auto;
    padding: 20px;
    border: 1px solid #888;
    width: 80%;
}

/* The Close Button */
.close {
    color: #aaaaaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
}

.close:hover,
.close:focus {
    color: #000;
    text-decoration: none;
    cursor: pointer;
}

.print-area .pdf-visible {
    display: block !important;
    overflow-wrap: anywhere;
}

.print-area .pdf-hidden,
.print-area .cke {
    display: none !important;
}

.print-area .pdf-valign-top {
    vertical-align: top !important;
}

.print-area input::-webkit-outer-spin-button,
.print-area input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/*
li.rstm-tree-item {
    display: none;
}

li.rstm-tree-item:has(> .rstm-toggle-icon) {
    display: list-item;
}
*/

[type="search"].rstm-search::placeholder {
    color: gray !important;
}
